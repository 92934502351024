const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
    next_show: 'Space Booking for Next Show',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3f12b9cf-d6c3-46c7-8690-03affac58d7f.png',
        '/booth/dY9EtsaKPJCjiURQ6goeoyVr9EpmAJEVGhJ5a9YXCzVj',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/4edd905f-fe2f-45bf-89d3-7d4b7a62dadd.jpg',
        '/booth/dY9EtsaKPJCjiURQ6goeoyVr9EpmAJEVGhJ5a9YXCzVj',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/8d4c4560-4618-4d84-bc87-de84955da9bb.jpg',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6952f5a4-b13f-4cf1-9794-a45512d76e83.jpg',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d863718a-3bff-4f41-aa3b-a984d5cc6da9.jpg',
        'https://www.content-tokyo.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
    next_show: '次年度 出展に興味のある方',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/b4e91f22-116e-451b-ad35-1ec7916025f4.png',
        '/booth/dY9EtsaKPJCjiURQ6goeoyVr9EpmAJEVGhJ5a9YXCzVj',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/145db797-2eff-402f-88a6-2de27ed45614.jpg',
        '/booth/dY9EtsaKPJCjiURQ6goeoyVr9EpmAJEVGhJ5a9YXCzVj',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/cc11b316-a261-4e42-ad83-8bb3ad04c8e0.jpg',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/632c6fec-fc7b-4717-b6e7-4ba5081ce46a.jpg',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d863718a-3bff-4f41-aa3b-a984d5cc6da9.jpg',
        'https://www.content-tokyo.jp/',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
